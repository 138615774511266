import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import GetIntlArrayPrimitive from "../../utils/GetIntlArrayPrimitive";

import ColumnLayout from "./mobile";

export default function Together() {
  const intl = useIntl();
  const weText = GetIntlArrayPrimitive("we.w", intl.messages);
  const youText = GetIntlArrayPrimitive("you.y", intl.messages);

  return (
    <div>
      <ColumnLayout weText={weText} youText={youText} />
    </div>
  );
}
