import React from "react";
import Fade from "react-reveal/Fade";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage } from "gatsby-plugin-intl";

export default function Team() {
  const imageQuery = useStaticQuery(graphql`
    {
      team: file(relativePath: { eq: "photos/Team.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      teamCropped: file(relativePath: { eq: "photos/TeamCropped.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <div className="d-content team pb-0">
      <Fade bottom>
        <div className="team__title">
          <h2 className="title is-size-1 is-size-2-mobile has-text-centered orange-color-text">
            <FormattedMessage id="team.title" />
          </h2>
        </div>
      </Fade>
      <div className="team__overlay">
        <GatsbyImage alt="Dekiru team members" className="team__photo" image={imageQuery.teamCropped.childImageSharp.gatsbyImageData} />
      </div>
    </div>
  );
}
