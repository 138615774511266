import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import Team from "../components/together/team";
import TogetherComponent from "../components/together/together";

export default function Together() {
  return (
    <Layout>
      <div className="together mt-6 pt-6">
        <div className="together__title">
          <p className="title is-size-1 is-size-2-mobile is-spaced has-text-centered orange-color-text">
            <FormattedMessage id="together.title" />
          </p>
        </div>
        <p className="is-spaced has-text-centered py-3">
          <FormattedMessage id="together.ingress" />
        </p>
        <TogetherComponent />
        <Team />
      </div>
    </Layout>
  );
}
