import React from "react";
import Fade from "react-reveal/Fade";
import { FormattedMessage } from "gatsby-plugin-intl";

interface Props {
  weText: string[];
  youText: string[];
}

export default function ColumnLayout({ weText, youText }: Props) {
  return (
    <div className="d-content d-content--pad">
      <div className="columns is-centered">
        <div className="column is-5-widescreen is-12-mobile">
          <div className="together__block">
            <h2 className="title is-size-1 is-size-2-mobile has-text-centered orange-color-text">
              <FormattedMessage id="we.title" />
            </h2>
            <div className="columns is-centered is-multiline">
              {weText.map((t, i) => (
                <div key={`we_${i}`} className="column is-6">
                  <Fade bottom cascade delay={100 * i}>
                    <div>
                      <div className="box has-text-centered">{t}</div>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="column is-5-widescreen is-12-mobile">
          <div className="together__block">
            <h2 className="title is-size-1 is-size-2-mobile has-text-centered orange-color-text">
              <FormattedMessage id="you.title" />
            </h2>
            <div className="columns is-centered is-multiline">
              {youText.map((t, i) => (
                <div key={`you_${i}`} className="column is-6">
                  <Fade bottom cascade delay={100 * i}>
                    <div>
                      <div className="box has-text-centered">{t}</div>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
